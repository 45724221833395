import React from 'react';
import Header from '@edx/frontend-component-header';
import Footer from '@edx/frontend-component-footer';
import { Switch, useLocation, Redirect } from 'react-router-dom';
import { PageRoute } from '@edx/frontend-platform/react';
import {
  CourseAbout,
  NotFoundPage,
  CoursesAndVideos,
  SeriesAbout,
} from './pages';

const App = () => {
  const location = useLocation();

  const isCourseAboutPage = location.pathname.endsWith('/about');

  return (
    <div className="main-wrapper">
      <Header />
      <Switch>
        <PageRoute exact path="/notfound" component={NotFoundPage} />
        <PageRoute path="*">
          <div id="frontend-component-custom-search">
            <div className="main-container">
              <main id="main" aria-label="Content" tabIndex="-1">
                  <section className={`find-courses ${isCourseAboutPage ? 'course-about-page' : ''}`}>
                  <section className="courses-container">
                    {location.pathname === '/courses' && (
                    <h1 className="search-title">
                      Courses & Videos
                    </h1>
                    )}
                    <Switch>
                      <PageRoute exact path="/courses" component={CoursesAndVideos} />
                      <PageRoute path="/courses/:courseId/about" component={CourseAbout} />
                      <PageRoute path="/series/:seriesId" component={SeriesAbout} />
                      <PageRoute path="*" component={() => <Redirect to="/notfound" />} />
                    </Switch>
                  </section>
                </section>
              </main>
            </div>
          </div>
        </PageRoute>
      </Switch>
      <Footer />
    </div>
  );
};

export default App;
